import { defineStore, storeToRefs } from "pinia";
import type {
  IndividualPriorityInfo,
  Info,
  InfoData,
} from "@/types/application/applicationData";
import type { InfoValueListType } from "@/utils/infoUtils";

export function useInfoDataStore(id: string) {
  return defineStore(id + "InfoDataStore", () => {
    const infoData = ref<InfoData>();
    const infoDataNewPanel = ref<InfoData>();

    function $reset() {
      infoData.value = undefined;
      infoDataNewPanel.value = undefined;
    }

    const { schoolIdAdmissionPointsMap } = storeToRefs(
      useAdmissionPointStructuresStore(id),
    );

    function initializeInfoDataStore(
      initialInfoData: InfoData,
      initialInfoDataNewPanel: InfoData,
    ) {
      infoData.value = initialInfoData;
      infoDataNewPanel.value = initialInfoDataNewPanel;
    }

    const infoList = computed(() => {
      let allInfoList: Info[] = [];
      if (infoData.value?.infoList) {
        allInfoList = allInfoList.concat(infoData.value.infoList);
      }
      if (infoDataNewPanel.value?.infoList) {
        allInfoList = allInfoList.concat(infoDataNewPanel.value.infoList);
      }

      return allInfoList;
    });

    const admissionPointInfoList = computed(() => {
      return infoData.value?.individualInfoList?.filter(
        (info) => !info.forQualificationGroup,
      );
    });

    const qualificationGroupInfoList = computed(() => {
      return infoData.value?.individualInfoList?.filter(
        (info) => info.forQualificationGroup,
      );
    });

    const admissionPointPriorityInfoMap = computed(() => {
      return infoData.value?.admissionPointPriorityInfoMap;
    });

    const schoolPriorityInfoMap = computed(() => {
      return infoData.value?.schoolPriorityInfoMap;
    });

    const infoIds = computed(() => {
      return infoList.value?.map((info) => info.id) ?? [];
    });

    const infoMap = computed(() => {
      const resultMap = new Map<number, Info>();
      for (const info of infoList.value ?? []) {
        resultMap.set(info.id, info);
      }
      for (const info of admissionPointInfoList.value ?? []) {
        resultMap.set(info.id, info);
      }
      for (const info of qualificationGroupInfoList.value ?? []) {
        resultMap.set(info.id, info);
      }

      return resultMap;
    });

    const infoValueListMap = computed(() => {
      const resultMap: InfoValueListType = new Map();
      for (const info of infoList.value ?? []) {
        if (
          !showAsMultipleSelect(info, undefined, undefined) ||
          !info.parametersList
        ) {
          continue;
        }
        const values: Record<"id" | "value" | "type", string>[] = [];
        resultMap.set(info.id, values);
        for (let i = 0; i < info.parametersList.length; i++) {
          values.push({
            id: info.parametersList[i],
            value: info.parametersList[i],
            type: "MULTIPLESELECT",
          });
        }
      }
    });

    const admissionPointInfoValueListMap = computed(() => {
      const resultMap = new Map<number, InfoValueListType>();
      for (const admissionPointIdString in admissionPointPriorityInfoMap.value) {
        const admissionPointId = parseInt(admissionPointIdString);
        if (!isNaN(admissionPointId)) {
          const admissionPointPriorityInfos =
            admissionPointPriorityInfoMap.value[admissionPointId];
          addAdmissionPointInfoValueList(
            admissionPointId,
            resultMap,
            admissionPointPriorityInfos,
          );
        }
      }

      for (const schoolIdString in schoolPriorityInfoMap.value) {
        const schoolId = parseInt(schoolIdString);
        if (!isNaN(schoolId)) {
          const schoolPriorityInfos = schoolPriorityInfoMap.value[schoolId];
          for (const admissionPoint of schoolIdAdmissionPointsMap.value.get(
            schoolId,
          ) ?? []) {
            addAdmissionPointInfoValueList(
              admissionPoint.admissionPointId,
              resultMap,
              schoolPriorityInfos,
            );
          }
        }
      }

      return resultMap;
    });

    function addAdmissionPointInfoValueList(
      admissionPointId: number,
      targetMap: Map<number, InfoValueListType>,
      individuialPriorityInfos: IndividualPriorityInfo[],
    ) {
      if (
        individuialPriorityInfos.length === 0 &&
        admissionPointInfoList.value?.length === 0
      ) {
        return;
      }
      let admissionPointInfoValueList = targetMap.get(admissionPointId);
      if (admissionPointInfoValueList === undefined) {
        admissionPointInfoValueList = new Map();
        targetMap.set(admissionPointId, admissionPointInfoValueList);
      }

      addAssociatedWithOfferFieldInfoValues(
        individuialPriorityInfos,
        infoMap,
        admissionPointId,
        targetMap,
        admissionPointInfoValueList,
      );

      addNotAssociatedWithOfferFieldInfoValues(admissionPointInfoValueList);
    }

    function addNotAssociatedWithOfferFieldInfoValues(
      admissionPointInfoValueList: InfoValueListType,
    ) {
      for (const admissionPointInfo of admissionPointInfoList.value ?? []) {
        if (
          admissionPointInfo?.associatedWithOfferField ||
          !admissionPointInfo.parametersList
        ) {
          continue;
        }
        admissionPointInfoValueList.set(
          admissionPointInfo.id,
          admissionPointInfo.parametersList.map((value) => {
            return {
              id: value,
              value: value,
              type: admissionPointInfo.infoType._name,
            };
          }),
        );
      }
    }

    function addAssociatedWithOfferFieldInfoValues(
      individuialPriorityInfos: IndividualPriorityInfo[],
      infoMap: globalThis.ComputedRef<Map<number, Info>>,
      admissionPointId: number,
      targetMap: Map<number, InfoValueListType>,
      admissionPointInfoValueList: InfoValueListType,
    ) {
      for (const individuialPriorityInfo of individuialPriorityInfos) {
        const info = infoMap.value.get(individuialPriorityInfo.infoId);
        if (!info?.associatedWithOfferField) {
          continue;
        }
        admissionPointInfoValueList.set(
          individuialPriorityInfo.infoId,
          individuialPriorityInfo.values.map((value) => {
            return {
              id: value,
              value: value,
              type: individuialPriorityInfo.type._name,
            };
          }),
        );
      }
    }

    return {
      infoList,
      admissionPointInfoList,
      qualificationGroupInfoList,
      infoMap,
      infoIds,
      initializeInfoDataStore,
      admissionPointInfoValueListMap,
      infoValueListMap,
      $reset,
    };
  })();
}
