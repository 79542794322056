import { defineStore, storeToRefs } from "pinia";
import type {
  CriteriaData,
  CriteriaGroup,
  CriteriaDefinition,
  CriteriaDefinitionScope,
} from "~/types/application/applicationData";

export function useCriteriaStructuresStore(id: string) {
  return defineStore(id + "CriteriaStructuresStore", () => {
    const criteriaData = ref<CriteriaData>();
    const admissionPointCriteriaMap = ref<Record<number, number[]>>({});
    const alwaysShowPromoteSharedCriteria = ref(false);

    function $reset() {
      criteriaData.value = undefined;
      admissionPointCriteriaMap.value = {};
      alwaysShowPromoteSharedCriteria.value = false;
    }

    const { admissionPointMap } = storeToRefs(
      useAdmissionPointStructuresStore(id),
    );
    const { priorities, qualificaitonGroupPriorityMap } = storeToRefs(
      usePrioritySelectionStore(id),
    );
    const { regionPrioritiesAdmissionPointIds } = storeToRefs(
      useRegionStore(id),
    );

    const { applicationData } = storeToRefs(useApplicationDataStore(id));
    const { isRegionOnly } = storeToRefs(useRegionStore(id));

    const skipCritariasForSpecial = computed(() => {
      return (
        !applicationData.value?.application.continuing &&
        applicationData.value?.application.special &&
        !applicationData.value?.formAdmissionPointData.options
          .multiplePrioritiesForIntegrational
      );
    });

    const showCriteriaPart = computed(() => {
      const criteriaData = applicationData.value?.formTemplateData.criteriaData;
      return (
        criteriaData?.options.enabled &&
        (!criteriaData?.options.skipCriteriasForRegional ||
          !isRegionOnly.value) &&
        !skipCritariasForSpecial.value
      );
    });

    const hideCriteriaDefinitionIds = computed(() => {
      if (
        criteriaData.value === undefined ||
        !criteriaData.value.options.hideCriteriaBasedOnAdmissionPointType ||
        !criteriaData.value.admissionPointTypeCriteriaMap
      ) {
        return [] as number[];
      }
      const hiddenCriteriaDefinitionIdsInPrioritiesAdmissionPointTypes = [];
      for (let i = 0; i < priorities.value.length; i++) {
        const priority = priorities.value[i];
        if (!criteriaData.value.admissionPointTypeCriteriaMap) {
          return;
        }
        const admissionPoint = admissionPointMap.value.get(
          priority.admissionPointId,
        );
        if (admissionPoint) {
          const admissionPointTypeCriteriaList =
            criteriaData.value.admissionPointTypeCriteriaMap[
              admissionPoint.admissionPointTypeId
            ];
          if (admissionPointTypeCriteriaList) {
            const hiddenCriteriaDefinitionIds = admissionPointTypeCriteriaList
              .filter((admissionPointTypeCriteria) => {
                return (
                  admissionPointTypeCriteria.type._name === "FORBID" ||
                  admissionPointTypeCriteria.type._name === "HIDDEN"
                );
              })
              .map(
                (admissionPointTypeCriteria) =>
                  admissionPointTypeCriteria.criteriaDefinitionId,
              );
            hiddenCriteriaDefinitionIdsInPrioritiesAdmissionPointTypes.push(
              hiddenCriteriaDefinitionIds,
            );
          }
        }
      }

      return Array.from(
        intersectionAll(
          hiddenCriteriaDefinitionIdsInPrioritiesAdmissionPointTypes,
        ),
      );
    });

    const criteriaDefinitionIdsWithoutRegion = computed(() => {
      if (criteriaData.value === undefined) {
        return [];
      }
      return Object.keys(criteriaData.value.criteriaValueMap)
        .filter(
          (criteriaDefinitionId) =>
            criteriaDefinitionId !==
            criteriaData.value?.regionCriteriaDefinitionId?.toString(),
        )
        .map((criteriaDefinitionId) => Number(criteriaDefinitionId));
    });

    const hiddenCriteriaDefinitionIdsIfRegion = computed(() => {
      const resultMap = new Map<number, number[]>();
      for (const priority of priorities.value) {
        if (
          criteriaData.value &&
          criteriaData.value.options.hideCriteriaIfRegion &&
          regionPrioritiesAdmissionPointIds.value.includes(
            priority.admissionPointId,
          )
        ) {
          resultMap.set(
            priority.admissionPointId,
            criteriaDefinitionIdsWithoutRegion.value,
          );
        }
      }
      return resultMap;
    });

    const priorityPlacementForPriority = computed(() => {
      const resultMap = new Map<number, number>();
      const sortedList = [...priorities.value].sort((a, b) => {
        if (a.qualificationGroupOrder !== b.qualificationGroupOrder) {
          return a.qualificationGroupOrder - b.qualificationGroupOrder;
        }
        return a.priority - b.priority;
      });
      sortedList.forEach((item, index) => {
        resultMap.set(item.admissionPointId, index + 1);
      });

      return resultMap;
    });

    const prioritySchoolPlacementForPriority = computed(() => {
      const resultMap = new Map<number, number>();
      const sortedList = [...priorities.value].sort((a, b) => {
        if (a.qualificationGroupOrder !== b.qualificationGroupOrder) {
          return a.qualificationGroupOrder - b.qualificationGroupOrder;
        }
        return a.priority - b.priority;
      });
      let schoolId: number | undefined;
      let priorityPlacement: number = 0;
      sortedList.forEach((priority) => {
        const prioritySchoolId = admissionPointMap.value.get(
          priority.admissionPointId,
        )?.schoolId;
        if (prioritySchoolId && prioritySchoolId != schoolId) {
          schoolId = prioritySchoolId;
          priorityPlacement++;
        }
        resultMap.set(priority.admissionPointId, priorityPlacement);
      });

      return resultMap;
    });

    const scopesForPriority = computed(() => {
      const resultMap = new Map<number, CriteriaDefinitionScope[]>();
      const currentSchool = applicationData.value?.variables.currentSchool;
      if (criteriaData.value === undefined) {
        return resultMap;
      }
      const criteriaEnabled =
        criteriaData.value.options.criteriaEnabled ||
        criteriaData.value.options.onlySelectedCriteriaEnabled;
      for (const priority of priorities.value) {
        const scopes: CriteriaDefinitionScope[] = criteriaEnabled
          ? ["INDIVIDUAL_SCHOOL", "INDIVIDUAL_PROFILE", "INDIVIDUAL"]
          : [];
        if (
          criteriaData.value.options.showPromoteSharedCriterias ||
          alwaysShowPromoteSharedCriteria.value
        ) {
          scopes.push("PROMOTE_SHARED");
          scopes.push("PROMOTE");
        }
        if (
          criteriaEnabled &&
          regionPrioritiesAdmissionPointIds.value.includes(
            priority.admissionPointId,
          )
        ) {
          scopes.push("REGION");
        }
        const qualificationGroupPriorities =
          qualificaitonGroupPriorityMap.value.get(
            priority.qualificationGroupId,
          );
        if (
          criteriaEnabled &&
          qualificationGroupPriorities !== undefined &&
          qualificationGroupPriorities.length > 0
        ) {
          scopes.push("DYNAMIC_PRIORITY");
          scopes.push("DYNAMIC_PRIORITY_PROFILE");
          scopes.push("DYNAMIC_PRIORITY_SCHOOL");
          scopes.push("DYNAMIC_PRIORITY_SCHOOL_PROFILE");
          const firstSchoolId = admissionPointMap.value.get(
            qualificationGroupPriorities[0].admissionPointId,
          )?.schoolId;
          if (priority.priority === 1) {
            scopes.push("FIRST_PRIORITY");
            scopes.push("FIRST_PRIORITY_PROFILE");
          }
          if (
            firstSchoolId &&
            admissionPointMap.value.get(priority.admissionPointId)?.schoolId ===
              firstSchoolId
          ) {
            scopes.push("FIRST_SCHOOL");
            scopes.push("FIRST_SCHOOL_PROFILE");
          }
          if (
            currentSchool?.id === priority.admissionPoint.schoolId &&
            criteriaData.value.currentSchoolCriteriaDefinitionId &&
            admissionPointCriteriaMap.value[priority.admissionPointId] &&
            admissionPointCriteriaMap.value[priority.admissionPointId].includes(
              criteriaData.value.currentSchoolCriteriaDefinitionId,
            )
          ) {
            scopes.push("CURRENT_SCHOOL");
          }
          if (
            currentSchool?.id !== priority.admissionPoint.schoolId &&
            admissionPointMap.value.get(priority.admissionPointId)
              ?.locationId === currentSchool?.locationId &&
            criteriaData.value.currentSchoolLocationCriteriaDefinitionId &&
            admissionPointCriteriaMap.value[priority.admissionPointId] &&
            admissionPointCriteriaMap.value[priority.admissionPointId].includes(
              criteriaData.value.currentSchoolLocationCriteriaDefinitionId,
            )
          ) {
            scopes.push("CURRENT_SCHOOL_LOCATION");
          }
        }
        resultMap.set(priority.admissionPointId, scopes);
      }

      return resultMap;
    });

    function filterCriteriaDefinitions(
      criteriaGroup: CriteriaGroup,
      onlySelectedCriteriaEnabled: boolean,
      scopes?: CriteriaDefinitionScope[],
      admissionPointId?: number,
    ) {
      const priorityPlacement = admissionPointId
        ? priorityPlacementForPriority.value.get(admissionPointId)
        : undefined;
      const hiddenCriteriaDefinitionIds = admissionPointId
        ? hiddenCriteriaDefinitionIdsIfRegion.value.get(admissionPointId)
        : hideCriteriaDefinitionIds.value;
      const prioritySchoolPlacement = admissionPointId
        ? prioritySchoolPlacementForPriority.value.get(admissionPointId)
        : undefined;
      const printedCriteriaDefinitionIds = admissionPointId
        ? admissionPointCriteriaMap.value[admissionPointId] ?? []
        : [];

      if (!scopes || !criteriaGroup.criteriaDefinitions) {
        return criteriaGroup.criteriaDefinitions;
      }

      return criteriaGroup.criteriaDefinitions.filter((criteriaDefinition) => {
        if (
          onlySelectedCriteriaEnabled &&
          !criteriaDefinition.applicationRegistrationEditable
        ) {
          return false;
        }

        if (
          admissionPointId &&
          printedCriteriaDefinitionIds &&
          !printedCriteriaDefinitionIds.includes(criteriaDefinition.id)
        ) {
          return false;
        }

        if (
          criteriaDefinition.autoSelected &&
          criteriaDefinition.autoSelectedResidence &&
          (!applicationData.value ||
            applicationData.value.formTemplateData.options
              .localizationCityId !==
              applicationData.value.application.student.contactAddress.cityId)
        ) {
          return false;
        }

        if (
          criteriaDefinition.autoSelectedBirthYear &&
          (!applicationData.value ||
            !applicationData.value.application.student.birthDate ||
            criteriaDefinition.autoSelectedBirthYear !==
              new Date(
                applicationData.value.application.student.birthDate,
              ).getFullYear())
        ) {
          return false;
        }

        if (
          hiddenCriteriaDefinitionIds &&
          hiddenCriteriaDefinitionIds.includes(criteriaDefinition.id)
        ) {
          return false;
        }

        if (
          isDynamicPriorityCriteria(criteriaDefinition) &&
          !isDynamicPriorityCriteriaMatchingPriority(
            criteriaDefinition,
            prioritySchoolPlacement,
            priorityPlacement,
          )
        ) {
          return false;
        }

        if (!scopes) {
          return true;
        }

        return scopes.includes(criteriaDefinition.scope._name);
      });
    }

    function isDynamicPriorityCriteria(
      criteriaDefinition: CriteriaDefinition,
    ): boolean {
      const scopeName = criteriaDefinition.scope._name;

      switch (scopeName) {
        case "DYNAMIC_PRIORITY":
        case "DYNAMIC_PRIORITY_PROFILE":
        case "DYNAMIC_PRIORITY_SCHOOL":
        case "DYNAMIC_PRIORITY_SCHOOL_PROFILE":
          return true;
        default:
          return false;
      }
    }

    function isDynamicPriorityCriteriaMatchingPriority(
      criteriaDefinition: CriteriaDefinition,
      prioritySchoolPlacement?: number,
      priorityPlacement?: number,
    ): boolean {
      const scopeName = criteriaDefinition.scope._name;

      switch (scopeName) {
        case "DYNAMIC_PRIORITY_SCHOOL":
        case "DYNAMIC_PRIORITY_SCHOOL_PROFILE":
          return (
            criteriaDefinition.forPriority !== undefined &&
            prioritySchoolPlacement !== undefined &&
            criteriaDefinition.forPriority === prioritySchoolPlacement
          );
        default:
          return (
            criteriaDefinition.forPriority !== undefined &&
            priorityPlacement !== undefined &&
            criteriaDefinition.forPriority === priorityPlacement
          );
      }
    }

    return {
      criteriaData,
      admissionPointCriteriaMap,
      hideCriteriaDefinitionIds,
      criteriaDefinitionIdsWithoutRegion,
      hiddenCriteriaDefinitionIdsIfRegion,
      scopesForPriority,
      priorityPlacementForPriority,
      prioritySchoolPlacementForPriority,
      showCriteriaPart,
      alwaysShowPromoteSharedCriteria,
      $reset,
      filterCriteriaDefinitions,
    };
  })();
}
