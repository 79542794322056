import { defineStore, storeToRefs } from "pinia";
import type {
  AdditionalCriteria,
  CriteriaValue,
  ExemptionCriteriaValue,
  Priority,
} from "~/types/application/applicationData";

export function useCriteriaSelectionStore(id: string) {
  return defineStore(id + "CriteriaSelectionStore", () => {
    const selectedCriteria = ref<Record<number, number>>({});
    const selectedCriteriaIndividual = ref<
      Record<number, Record<number, number>>
    >({});
    const selectedCriteriaPromoteShared = ref<
      Record<number, Record<number, number>>
    >({});
    const { applicationData } = storeToRefs(useApplicationDataStore(id));
    const dynamicCriteriaMap = ref<Record<number, string>>({});
    const additionalCriteriaList = ref<AdditionalCriteria[]>([]);
    const promoteSharedCriteriaEditable = ref(false);

    function $reset() {
      selectedCriteria.value = {};
      selectedCriteriaIndividual.value = {};
      selectedCriteriaPromoteShared.value = {};
      dynamicCriteriaMap.value = {};
      additionalCriteriaList.value = [];
      promoteSharedCriteriaEditable.value = false;
    }

    const { admissionPointSelection } = storeToRefs(
      usePrioritySelectionStore(id),
    );
    const { criteriaData, showCriteriaPart, hideCriteriaDefinitionIds } =
      storeToRefs(useCriteriaStructuresStore(id));

    const { regionPrioritiesAdmissionPointIds, regionPriorities } = storeToRefs(
      useRegionStore(id),
    );

    const criteriaIdsApplication = computed(() => {
      return criteriaIdsFromRecord(selectedCriteria.value);
    });

    const criteriaValueToCriteriaDefinition = computed(() => {
      const resultMap = new Map<number, number>();
      addCriteriaValueToCriteriaDefinitionEntries(
        resultMap,
        criteriaData.value?.criteriaValueMap,
      );
      addCriteriaValueToCriteriaDefinitionEntries(
        resultMap,
        criteriaData.value?.exemptionCriteriaValueMap,
      );

      return resultMap;
    });

    const criteriaIdsPriority = computed(() => {
      const resultMap: Record<number, number[]> = {};
      if (!admissionPointSelection.value) {
        return resultMap;
      }
      for (const admissionPointId of admissionPointSelection.value) {
        if (selectedCriteriaIndividual.value[admissionPointId]) {
          resultMap[admissionPointId] = criteriaIdsFromRecord(
            selectedCriteriaIndividual.value[admissionPointId],
          );
        }
      }
      return resultMap;
    });

    const criteriaIdsAdditional = computed(() => {
      const resultMap: Record<number, number[]> = {};
      if (!admissionPointSelection.value) {
        return resultMap;
      }
      for (const admissionPointId of admissionPointSelection.value) {
        if (selectedCriteriaPromoteShared.value[admissionPointId]) {
          resultMap[admissionPointId] = criteriaIdsFromRecord(
            selectedCriteriaPromoteShared.value[admissionPointId],
          );
        }
      }
      return resultMap;
    });

    watch(regionPrioritiesAdmissionPointIds, (newValue) => {
      if (
        criteriaData.value &&
        criteriaData.value.options.hideCriteriaIfRegion &&
        criteriaData.value.regionCriteriaDefinitionId !== undefined
      ) {
        for (const admissionPointId of newValue) {
          for (const criteriaDefinitionId in selectedCriteriaIndividual.value[
            admissionPointId
          ]) {
            if (
              Number(criteriaDefinitionId) !==
              criteriaData.value.regionCriteriaDefinitionId
            ) {
              delete selectedCriteriaIndividual.value[admissionPointId][
                criteriaDefinitionId
              ];
            }
          }
        }
      }
    });

    watch(regionPriorities, (newValue) => {
      for (let i = 0; i < newValue.length; i++) {
        const regionPriority = newValue[i];
        const regionCriteriaDefinitionId =
          criteriaData.value?.regionCriteriaDefinitionId;
        if (
          regionCriteriaDefinitionId !== undefined &&
          criteriaData.value !== undefined
        ) {
          const regionCriteriaId =
            criteriaData.value.criteriaValueMap[regionCriteriaDefinitionId][0]
              .id;
          if (
            !selectedCriteriaIndividual.value[regionPriority.admissionPointId]
          ) {
            selectedCriteriaIndividual.value[regionPriority.admissionPointId] =
              {};
          }
          selectedCriteriaIndividual.value[regionPriority.admissionPointId][
            regionCriteriaDefinitionId
          ] = regionCriteriaId;
        }
      }
    });

    watch(hideCriteriaDefinitionIds, (newValue) => {
      if (newValue) {
        for (const hiddenId of newValue) {
          if (hiddenId in selectedCriteria.value) {
            delete selectedCriteria.value[hiddenId];
          }
          if (hiddenId in dynamicCriteriaMap.value) {
            delete dynamicCriteriaMap.value[hiddenId];
          }
        }
      }
    });

    watch(admissionPointSelection, (newValue) => {
      const newValueStrings = newValue?.map((value) => value.toString());
      const existingIndividualCriteriaKeys = Object.keys(
        selectedCriteriaIndividual.value,
      );
      const admissionPointIdsToAdd = newValue?.filter(
        (admissionPointId) =>
          !existingIndividualCriteriaKeys.includes(admissionPointId.toString()),
      );
      const admissionPointIdsToRemove = existingIndividualCriteriaKeys.filter(
        (admissionPointId) =>
          !newValueStrings || !newValueStrings.includes(admissionPointId),
      );
      admissionPointIdsToAdd?.forEach((admissionPointId) => {
        if (!selectedCriteriaIndividual.value[Number(admissionPointId)]) {
          selectedCriteriaIndividual.value[Number(admissionPointId)] = {};
        }
        if (!selectedCriteriaPromoteShared.value[Number(admissionPointId)]) {
          selectedCriteriaPromoteShared.value[Number(admissionPointId)] = {};
        }
      });

      admissionPointIdsToRemove.forEach((admissionPointId) => {
        delete selectedCriteriaIndividual.value[Number(admissionPointId)];
        delete selectedCriteriaPromoteShared.value[Number(admissionPointId)];
      });
    });

    watch(showCriteriaPart, (newValue) => {
      if (newValue === false) {
        selectedCriteria.value = {};
        dynamicCriteriaMap.value = {};
        for (const admissionPointId in selectedCriteriaIndividual.value) {
          for (const criteriaDefinitionId in selectedCriteriaIndividual.value[
            admissionPointId
          ]) {
            if (
              criteriaData.value?.regionCriteriaDefinitionId !==
              Number(criteriaDefinitionId)
            ) {
              delete selectedCriteriaIndividual.value[admissionPointId][
                criteriaDefinitionId
              ];
            }
          }
        }
      }
    });

    function initializeSelectedCriteria(criteriaIdList?: number[]) {
      selectedCriteria.value = {};
      if (criteriaIdList === undefined) {
        return;
      }
      if (
        applicationData.value?.variables.importMarks &&
        applicationData.value?.variables.criteriaForMarks
      ) {
        criteriaIdList = criteriaIdList.concat(
          applicationData.value?.variables.criteriaForMarks,
        );
      }
      if (
        applicationData.value?.variables.importStudentCriteria &&
        applicationData.value?.variables.criteriaForStudent
      ) {
        criteriaIdList = criteriaIdList.concat(
          applicationData.value?.variables.criteriaForStudent,
        );
      }
      criteriaIdList = criteriaIdList.filter((criteriaId) => {
        return criteriaValueToCriteriaDefinition.value.has(criteriaId);
      });
      criteriaIdList.forEach((criteriaId) => {
        const criteriaDefinitionId =
          criteriaValueToCriteriaDefinition.value.get(criteriaId);
        if (criteriaDefinitionId) {
          selectedCriteria.value[criteriaDefinitionId] = criteriaId;
        }
      });
    }

    function initializeSelectedCriteriaIndividual(priorities?: Priority[]) {
      selectedCriteriaIndividual.value = {};
      if (!priorities) {
        return;
      }
      for (let i = 0; i < priorities.length; i++) {
        const priority = priorities[i];
        selectedCriteriaIndividual.value[priority.admissionPointId] =
          {} as Record<number, number>;
        priority.criteriaIdList.forEach((criteriaId) => {
          const criteriaDefinitionId =
            criteriaValueToCriteriaDefinition.value.get(criteriaId);
          if (criteriaDefinitionId) {
            selectedCriteriaIndividual.value[priority.admissionPointId][
              criteriaDefinitionId
            ] = criteriaId;
          }
        });
      }
    }

    function addCriteriaValueToCriteriaDefinitionEntries(
      resultMap: Map<number, number>,
      criteriaMap?:
        | Record<number, CriteriaValue[]>
        | Record<number, ExemptionCriteriaValue[]>,
    ) {
      if (criteriaMap) {
        Object.entries(criteriaMap).forEach((entry) => {
          entry[1].forEach((criteriaValue) => {
            resultMap.set(criteriaValue.id, parseInt(entry[0]));
          });
        });
      }
    }

    function criteriaIdsFromRecord(criteriaRecord: Record<number, number>) {
      return Object.entries(criteriaRecord).reduce<number[]>(
        (accumulator, entry) => {
          if (entry[1] && !accumulator.includes(entry[1])) {
            accumulator.push(entry[1]);
          }
          return accumulator;
        },
        [],
      );
    }

    return {
      selectedCriteria,
      selectedCriteriaIndividual,
      selectedCriteriaPromoteShared,
      dynamicCriteriaMap,
      additionalCriteriaList,
      criteriaIdsApplication,
      criteriaIdsPriority,
      criteriaIdsAdditional,
      promoteSharedCriteriaEditable,
      initializeSelectedCriteria,
      initializeSelectedCriteriaIndividual,
      $reset,
    };
  })();
}
