function intersectionArray<T>(array1: T[], array2: T[]): T[] {
  return array1.filter((element) => array2.includes(element));
}

export function intersection<T>(array1: T[], array2: T[]): Set<T> {
  return new Set(array1.filter((element) => array2.includes(element)));
}

export function intersectionAll<T>(array: T[][]): Set<T> {
  if (array.length <= 0) {
    return new Set();
  }
  return new Set(
    array.reduce(
      (aggregate, currentArray) => intersectionArray(aggregate, currentArray),
      array[0],
    ),
  );
}

export function difference<T>(array1: T[], array2: T[]): Set<T> {
  return new Set(array1.filter((element) => !array2.includes(element)));
}

export function isBooleanArray(value: unknown): value is boolean[] {
  if (!Array.isArray(value)) {
    return false;
  }
  for (const item of value) {
    if (typeof item !== "boolean") {
      return false;
    }
  }
  return true;
}

export function isStringArray(value: unknown): value is string[] {
  if (!Array.isArray(value)) {
    return false;
  }
  for (const item of value) {
    if (typeof item !== "string") {
      return false;
    }
  }
  return true;
}

export function isNumberArray(value: unknown): value is number[] {
  if (!Array.isArray(value)) {
    return false;
  }
  for (const item of value) {
    if (typeof item !== "number") {
      return false;
    }
  }
  return true;
}

export function arrayToMatrix(array: string[], n: number): string[][] {
  const chunkArray = (array: string[], size: number): string[][] => {
    const result: string[][] = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  return chunkArray(array, n);
}
