import { defineStore, storeToRefs } from "pinia";
import type {
  Info,
  PriorityInfo,
  Priority,
} from "@/types/application/applicationData";
import type {
  ExactlySelectedInGroupType,
  IndividualInfoSelectionType,
  GroupVisibleInfoType,
} from "@/utils/infoUtils";

export function useQualificationGroupInfoSelectionStore(id: string) {
  return defineStore(id + "QualificationGroupInfoSelectionStore", () => {
    const qualificationGroupSelectedInfo = ref<
      Map<number, IndividualInfoSelectionType>
    >(new Map());

    function $reset() {
      qualificationGroupSelectedInfo.value = new Map();
    }

    const { infoMap, qualificationGroupInfoList } = storeToRefs(
      useInfoDataStore(id),
    );

    const { selectedQualificationGroupIds } = storeToRefs(
      usePrioritySelectionStore(id),
    );

    const visibleInfoIds = computed(() => {
      const resultMap = new Map<number, Set<number>>();
      for (const qualificationGroupId of selectedQualificationGroupIds.value ??
        []) {
        const visibleInfoIds = new Set<number>();
        resultMap.set(qualificationGroupId, visibleInfoIds);
        const selectedInfo =
          qualificationGroupSelectedInfo.value.get(qualificationGroupId);
        for (const info of qualificationGroupInfoList.value ?? []) {
          if (isInfoVisible(info, selectedInfo, infoMap.value)) {
            visibleInfoIds.add(info.id);
          }
        }
      }
      return resultMap;
    });

    watch(
      visibleInfoIds,
      (newValue, oldValue) => {
        for (const qualificationGroupId of oldValue.keys()) {
          const newVisibleInfoIds = newValue.get(qualificationGroupId);
          const oldVisibleInfoIds = oldValue.get(qualificationGroupId);
          const selectedInfo =
            qualificationGroupSelectedInfo.value.get(qualificationGroupId);
          if (newVisibleInfoIds == undefined && selectedInfo != undefined) {
            clearInfoIds(oldVisibleInfoIds, selectedInfo);
          }
          const removedInfoIds = difference(
            Array.from(oldVisibleInfoIds ?? []),
            Array.from(newVisibleInfoIds ?? []),
          );
          clearInfoIds(removedInfoIds, selectedInfo);
        }
      },
      { deep: true },
    );

    const groupVisibleInfoMap = computed(() => {
      const resultMap = new Map<number, GroupVisibleInfoType>();
      for (const qualificationGroupId of selectedQualificationGroupIds.value ??
        []) {
        resultMap.set(
          qualificationGroupId,
          getGroupVisibleInfoMap(
            qualificationGroupInfoList.value,
            visibleInfoIds.value.get(qualificationGroupId),
          ),
        );
      }
      return resultMap;
    });

    const exactlySelectedInGroupValidationMap = computed(() => {
      const validationMap = new Map<number, ExactlySelectedInGroupType>();
      for (const [
        qualificationGroupId,
        groupVisibleInfo,
      ] of groupVisibleInfoMap.value.entries()) {
        const selectedInfo =
          qualificationGroupSelectedInfo.value.get(qualificationGroupId);
        validationMap.set(
          qualificationGroupId,
          getExactlySelectedInGroupValidationMap(
            groupVisibleInfo,
            selectedInfo,
          ),
        );
      }
      return validationMap;
    });

    const exactlySelectedInGroupValid = computed(() => {
      for (const exactlySelectedInGroupValidation of exactlySelectedInGroupValidationMap.value.values()) {
        if (!isExactlySelectedInGroupValid(exactlySelectedInGroupValidation)) {
          return false;
        }
      }
      return true;
    });

    watch(selectedQualificationGroupIds, (newValue, oldValue) => {
      if (newValue) {
        const addedQualificationGroupIds = newValue.filter(
          (qualificationGroupId) => !oldValue?.includes(qualificationGroupId),
        );
        for (const addedQualificationGroupId of addedQualificationGroupIds) {
          if (
            qualificationGroupSelectedInfo.value.has(addedQualificationGroupId)
          ) {
            continue;
          }
          const priorityInfoSelection = new Map<number, PriorityInfo>();
          qualificationGroupSelectedInfo.value.set(
            addedQualificationGroupId,
            priorityInfoSelection,
          );
          for (const individualInfo of qualificationGroupInfoList.value ?? []) {
            const newPriorityInfo = createNewPriorityInfo(individualInfo);
            resetInfoValue(newPriorityInfo, individualInfo);
            priorityInfoSelection.set(individualInfo.id, newPriorityInfo);
          }
        }

        const removedQualificationGroupIds = oldValue?.filter(
          (qualificationGroupId) => !newValue.includes(qualificationGroupId),
        );
        for (const removedQualificationGroupId of removedQualificationGroupIds ??
          []) {
          qualificationGroupSelectedInfo.value.delete(
            removedQualificationGroupId,
          );
        }
      }
    });

    function initializeSelectedInfo(initialPriorities: Priority[]) {
      qualificationGroupSelectedInfo.value = new Map();
      const qualificationGroupInfoIds = qualificationGroupInfoList.value?.map(
        (info) => info.id,
      );
      for (const priority of initialPriorities) {
        const priorityInfoSelection = new Map<number, PriorityInfo>();
        qualificationGroupSelectedInfo.value.set(
          priority.qualificationGroupId,
          priorityInfoSelection,
        );
        const initializedInfoIds = [];
        for (const priorityInfo of priority.infoList ?? []) {
          if (qualificationGroupInfoIds?.includes(priorityInfo.infoId)) {
            priorityInfoSelection.set(priorityInfo.infoId, priorityInfo);
            initializedInfoIds.push(priorityInfo.infoId);
          }
        }
        for (const info of qualificationGroupInfoList.value ?? []) {
          if (!initializedInfoIds.includes(info.id)) {
            const newPriorityInfo = createNewPriorityInfo(info);
            resetInfoValue(newPriorityInfo, info);
            priorityInfoSelection.set(info.id, newPriorityInfo);
            initializedInfoIds.push(info.id);
          }
        }
      }
    }

    function createNewPriorityInfo(info: Info): PriorityInfo {
      return {
        infoId: info.id,
        infoValue: "",
      };
    }

    function updateInfoValue<T>(
      info: Info,
      qualificationGroupId: number,
      newValue: T,
      otherAvailableValues?: string[] | number[] | boolean[],
    ): void {
      const selectedInfo =
        qualificationGroupSelectedInfo.value.get(qualificationGroupId);
      updateSelectedInfo(
        info,
        newValue,
        selectedInfo,
        undefined,
        undefined,
        otherAvailableValues,
      );
    }

    function getCheckboxValue(info: Info, qualificationGroupId: number) {
      const selectedInfo =
        qualificationGroupSelectedInfo.value.get(qualificationGroupId);
      return getCheckboxInfoValue(info, selectedInfo);
    }

    function getTextValue(info: Info, qualificationGroupId: number) {
      const selectedInfo =
        qualificationGroupSelectedInfo.value.get(qualificationGroupId);
      return getTextInfoValue(info, selectedInfo);
    }

    function getMultipleSelectValue(info: Info, qualificationGroupId: number) {
      const selectedInfo =
        qualificationGroupSelectedInfo.value.get(qualificationGroupId);
      return getMultipleSelectInfoValue(
        info,
        selectedInfo,
        undefined,
        undefined,
      );
    }

    return {
      qualificationGroupSelectedInfo,
      initializeSelectedInfo,
      updateInfoValue,
      getCheckboxValue,
      getTextValue,
      getMultipleSelectValue,
      groupVisibleInfoMap,
      exactlySelectedInGroupValidationMap,
      exactlySelectedInGroupValid,
      $reset,
    };
  })();
}
