import { defineStore, storeToRefs } from "pinia";
import type {
  Info,
  ApplicationInfo,
} from "@/types/application/applicationData";

export function useInfoSelectionStore(id: string) {
  return defineStore(id + "InfoSelectionStore", () => {
    const selectedInfo = ref<Map<number, ApplicationInfo>>(new Map());

    function $reset() {
      selectedInfo.value = new Map();
    }

    const { infoList, infoIds, infoMap } = storeToRefs(useInfoDataStore(id));

    const visibleInfoIds = computed(() => {
      const result = new Set<number>();
      for (const info of infoList.value ?? []) {
        if (isInfoVisible(info, selectedInfo.value, infoMap.value)) {
          result.add(info.id);
        }
      }
      return result;
    });

    watch(visibleInfoIds, (newValue, oldValue) => {
      const removedInfoIds = difference(
        Array.from(oldValue),
        Array.from(newValue),
      );
      clearInfoIds(removedInfoIds, selectedInfo.value);
    });

    const exactlySelectedInGroupValidationMap = computed(() => {
      return getExactlySelectedInGroupValidationMap(
        getGroupVisibleInfoMap(infoList.value, visibleInfoIds.value),
        selectedInfo.value,
      );
    });

    const exactlySelectedInGroupValid = computed(() => {
      return isExactlySelectedInGroupValid(
        exactlySelectedInGroupValidationMap.value,
      );
    });

    function initializeSelectedInfo(
      applicationInfoList: ApplicationInfo[] | undefined,
    ) {
      selectedInfo.value = new Map();
      if (applicationInfoList && applicationInfoList.length > 0) {
        setInfoValuesForSelectedInfos(applicationInfoList);
      }
      resetInfoValuesForNotSelectedInfos();
    }

    function setInfoValuesForSelectedInfos(
      applicationInfoList: ApplicationInfo[],
    ) {
      applicationInfoList = applicationInfoList.filter((applicationInfo) => {
        return infoIds.value && infoIds.value.includes(applicationInfo.infoId);
      });
      for (const applicationInfo of applicationInfoList) {
        const info = infoMap.value.get(applicationInfo.infoId);
        selectedInfo.value.set(applicationInfo.infoId, applicationInfo);
        if (info) {
          applicationInfo.info = {
            id: applicationInfo.infoId,
            required: info.required,
            infoType: info.infoType._name,
          };
        }
        if (showAsMultipleSelect(info, undefined, undefined)) {
          filterInitialMultipleSelectValuesNotInParametersList(applicationInfo);
        }
      }
    }

    function resetInfoValuesForNotSelectedInfos() {
      for (const info of infoList.value ?? []) {
        const selectedInfoEntry = selectedInfo.value.get(info.id);
        if (!selectedInfoEntry) {
          const newApplicationInfo = createNewApplicationInfo(info);
          resetInfoValue(newApplicationInfo, info);
          selectedInfo.value.set(info.id, newApplicationInfo);
        } else {
          resetInfoValue(selectedInfoEntry, info);
        }
      }
    }

    function filterInitialMultipleSelectValuesNotInParametersList(
      applicaitonInfo: ApplicationInfo,
    ) {
      const selectedValues = applicaitonInfo.infoValue?.split(";");
      if (selectedValues) {
        const info = infoMap.value.get(applicaitonInfo.infoId);
        const infoAvailableValues = getInfoAvailableValues(info, []);
        const filteredSelectedValues = filterValuesNotInList(
          selectedValues,
          infoAvailableValues,
        );
        applicaitonInfo.infoValue = filteredSelectedValues.join(";");
      }
    }

    function createNewApplicationInfo(info: Info): ApplicationInfo {
      return {
        infoId: info.id,
        infoValue: "",
        info: {
          id: info.id,
          required: info.required,
          infoType: info.infoType._name,
        },
      };
    }

    function updateInfoValue<T>(
      info: Info,
      newValue: T,
      otherAvailableValues?: string[] | number[] | boolean[],
    ): void {
      updateSelectedInfo(
        info,
        newValue,
        selectedInfo.value,
        undefined,
        undefined,
        otherAvailableValues,
      );
    }

    function getCheckboxValue(info: Info) {
      return getCheckboxInfoValue(info, selectedInfo.value);
    }

    function getTextValue(info: Info) {
      return getTextInfoValue(info, selectedInfo.value);
    }

    function getMultipleSelectValue(info: Info) {
      return getMultipleSelectInfoValue(
        info,
        selectedInfo.value,
        undefined,
        undefined,
      );
    }

    return {
      selectedInfo,
      initializeSelectedInfo,
      updateInfoValue,
      getCheckboxValue,
      getTextValue,
      getMultipleSelectValue,
      visibleInfoIds,
      exactlySelectedInGroupValid,
      exactlySelectedInGroupValidationMap,
      $reset,
    };
  })();
}
