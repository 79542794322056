import { defineStore } from "pinia";
import type {
  AdmissionPoint,
  AdmissionPointType,
  ApplicationType,
  QualificationGroup,
  SchoolDetailsForApplicationForm,
} from "~/types/application/applicationData";

export function useAdmissionPointStructuresStore(id: string) {
  return defineStore(id + "AdmissionPointStructures", () => {
    const admissionPointList = ref<AdmissionPoint[]>();
    const admissionPointTypeList = ref<AdmissionPointType[]>();
    const qualificationGroupList = ref<QualificationGroup[]>();
    const schoolDetailsMap =
      ref<Record<number, SchoolDetailsForApplicationForm>>();

    function $reset() {
      admissionPointList.value = undefined;
      admissionPointTypeList.value = undefined;
      qualificationGroupList.value = undefined;
      schoolDetailsMap.value = undefined;
    }

    const admissionPointMap = computed(() => {
      if (admissionPointList.value === undefined) {
        return new Map<number, AdmissionPoint>();
      }
      return new Map(
        admissionPointList.value.map((admissionPoint) => {
          return [admissionPoint.admissionPointId, admissionPoint];
        }),
      );
    });

    const qualificationGroupMap = computed(() => {
      return new Map(
        qualificationGroupList.value?.map((qualificationGroup) => {
          return [qualificationGroup.id, qualificationGroup];
        }),
      );
    });

    const admissionPointApplicationTypeMap = computed(() => {
      const resultMap = new Map<number, ApplicationType>();
      if (admissionPointList.value === undefined) {
        return resultMap;
      }
      for (const admissionPoint of admissionPointList.value) {
        const admissionPointType = admissionPointTypeMap.value.get(
          admissionPoint.admissionPointTypeId,
        );
        if (admissionPointType) {
          resultMap.set(
            admissionPoint.admissionPointId,
            admissionPointType.applicationType,
          );
        }
      }
      return resultMap;
    });

    const admissionPointTypeMap = computed(() => {
      if (admissionPointTypeList.value === undefined) {
        return new Map<number, AdmissionPointType>();
      }
      return new Map(
        admissionPointTypeList.value.map((admissionPointType) => [
          admissionPointType.admissionPointTypeId,
          admissionPointType,
        ]),
      );
    });

    const schoolIdAdmissionPointsMap = computed(() => {
      const resultMap = new Map<number, AdmissionPoint[]>();
      if (admissionPointList.value === undefined) {
        return resultMap;
      }
      for (const admissionPoint of admissionPointList.value) {
        const admissionPoints = resultMap.get(admissionPoint.schoolId);
        if (admissionPoints) {
          admissionPoints.push(admissionPoint);
        } else {
          resultMap.set(admissionPoint.schoolId, [admissionPoint]);
        }
      }
      return resultMap;
    });

    const schoolNameMap = computed(() => {
      const resultMap = new Map<number, string>();
      if (admissionPointList.value === undefined) {
        return resultMap;
      }
      for (const admissionPoint of admissionPointList.value) {
        resultMap.set(admissionPoint.schoolId, admissionPoint.schoolName);
      }
      return resultMap;
    });

    const schoolAddressMap = computed(() => {
      const resultMap = new Map<number, string>();
      if (schoolDetailsMap.value === undefined) {
        return resultMap;
      }
      for (const schoolId in schoolDetailsMap.value) {
        const schoolDetails = schoolDetailsMap.value[schoolId];
        if (schoolDetails) {
          resultMap.set(
            Number(schoolId),
            `${schoolDetails?.schoolAddressFirstLine}, ${schoolDetails?.schoolAddressSecondLine}`,
          );
        }
      }
      return resultMap;
    });

    function isBlockApply(admissionPointId: number) {
      const admissionPoint = admissionPointMap.value.get(admissionPointId);
      if (admissionPoint && admissionPoint.admissionPointTypeId) {
        const admissionPointType = admissionPointTypeMap.value.get(
          admissionPoint.admissionPointTypeId,
        );
        return admissionPointType && admissionPointType.blockApply;
      }
      return false;
    }

    function initialize(
      initialAdmissionPointList: AdmissionPoint[],
      initialAdmissionPointTypeList: AdmissionPointType[],
      initialQualificationGroupList: QualificationGroup[],
      initialSchoolDetailsMap?: Record<number, SchoolDetailsForApplicationForm>,
    ) {
      admissionPointList.value = initialAdmissionPointList;
      admissionPointTypeList.value = initialAdmissionPointTypeList;
      qualificationGroupList.value = initialQualificationGroupList;
      schoolDetailsMap.value = initialSchoolDetailsMap;
    }

    return {
      admissionPointList,
      admissionPointMap,
      qualificationGroupMap,
      admissionPointApplicationTypeMap,
      admissionPointTypeMap,
      schoolIdAdmissionPointsMap,
      schoolDetailsMap,
      schoolNameMap,
      schoolAddressMap,
      isBlockApply,
      initialize,
      $reset,
    };
  })();
}
