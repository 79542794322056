import { defineStore, storeToRefs } from "pinia";
import type {
  Info,
  PriorityInfo,
  Priority,
} from "@/types/application/applicationData";
import type {
  ExactlySelectedInGroupType,
  IndividualInfoSelectionType,
  GroupVisibleInfoType,
} from "@/utils/infoUtils";

export function useAdmissionPointInfoSelectionStore(id: string) {
  return defineStore(id + "AdmissionPointInfoSelectionStore", () => {
    const admissionPointSelectedInfo = ref<
      Map<number, IndividualInfoSelectionType>
    >(new Map());

    function $reset() {
      admissionPointSelectedInfo.value = new Map();
    }

    const { infoMap, admissionPointInfoList, admissionPointInfoValueListMap } =
      storeToRefs(useInfoDataStore(id));

    const { admissionPointSelection } = storeToRefs(
      usePrioritySelectionStore(id),
    );

    const visibleInfoIds = computed(() => {
      const resultMap = new Map<number, Set<number>>();
      for (const admissionPointId of admissionPointSelection.value ?? []) {
        const visibleInfoIds = new Set<number>();
        resultMap.set(admissionPointId, visibleInfoIds);
        const selectedInfo =
          admissionPointSelectedInfo.value.get(admissionPointId);
        for (const info of admissionPointInfoList.value ?? []) {
          if (isInfoVisible(info, selectedInfo, infoMap.value)) {
            visibleInfoIds.add(info.id);
          }
        }
      }
      return resultMap;
    });

    watch(
      visibleInfoIds,
      (newValue, oldValue) => {
        for (const admissionPointId of oldValue.keys()) {
          const newVisibleInfoIds = newValue.get(admissionPointId);
          const oldVisibleInfoIds = oldValue.get(admissionPointId);
          const selectedInfo =
            admissionPointSelectedInfo.value.get(admissionPointId);
          if (newVisibleInfoIds == undefined && selectedInfo != undefined) {
            clearInfoIds(oldVisibleInfoIds, selectedInfo);
          }
          const removedInfoIds = difference(
            Array.from(oldVisibleInfoIds ?? []),
            Array.from(newVisibleInfoIds ?? []),
          );
          clearInfoIds(removedInfoIds, selectedInfo);
        }
      },
      { deep: true },
    );

    const groupVisibleInfoMap = computed(() => {
      const resultMap = new Map<number, GroupVisibleInfoType>();
      for (const admissionPointId of admissionPointSelection.value ?? []) {
        resultMap.set(
          admissionPointId,
          getGroupVisibleInfoMap(
            admissionPointInfoList.value,
            visibleInfoIds.value.get(admissionPointId),
          ),
        );
      }
      return resultMap;
    });

    const exactlySelectedInGroupValidationMap = computed(() => {
      const validationMap = new Map<number, ExactlySelectedInGroupType>();
      for (const [
        admissionPointId,
        groupVisibleInfo,
      ] of groupVisibleInfoMap.value.entries()) {
        const selectedInfo =
          admissionPointSelectedInfo.value.get(admissionPointId);
        validationMap.set(
          admissionPointId,
          getExactlySelectedInGroupValidationMap(
            groupVisibleInfo,
            selectedInfo,
          ),
        );
      }
      return validationMap;
    });

    const exactlySelectedInGroupValid = computed(() => {
      for (const exactlySelectedInGroupValidation of exactlySelectedInGroupValidationMap.value.values()) {
        if (!isExactlySelectedInGroupValid(exactlySelectedInGroupValidation)) {
          return false;
        }
      }
      return true;
    });

    watch(admissionPointSelection, (newValue, oldValue) => {
      if (newValue) {
        const addedAdmissionPointIds = newValue.filter(
          (admissionPointId) => !oldValue?.includes(admissionPointId),
        );
        for (const addedAdmissionPointId of addedAdmissionPointIds) {
          if (admissionPointSelectedInfo.value.has(addedAdmissionPointId)) {
            continue;
          }
          const priorityInfoSelection = new Map<number, PriorityInfo>();
          admissionPointSelectedInfo.value.set(
            addedAdmissionPointId,
            priorityInfoSelection,
          );
          for (const individualInfo of admissionPointInfoList.value ?? []) {
            if (isInfoSupported(addedAdmissionPointId, individualInfo)) {
              const newPriorityInfo = createNewPriorityInfo(individualInfo);
              resetInfoValue(
                newPriorityInfo,
                individualInfo,
                getDefaultValue(addedAdmissionPointId, individualInfo.id),
              );
              priorityInfoSelection.set(individualInfo.id, newPriorityInfo);
            }
          }
        }

        const removedAdmissionPointIds = oldValue?.filter(
          (admissionPointId) => !newValue.includes(admissionPointId),
        );
        for (const removedAdmissionPointId of removedAdmissionPointIds ?? []) {
          admissionPointSelectedInfo.value.delete(removedAdmissionPointId);
        }
      }
    });

    function getDefaultValue(
      admissionPointId: number,
      infoId: number,
    ): string | undefined {
      const admissionPointInfoValue =
        admissionPointInfoValueListMap.value.get(admissionPointId);
      const admissionPointInfoValueList = admissionPointInfoValue?.get(infoId);
      if (admissionPointInfoValueList?.length === 1) {
        return admissionPointInfoValueList[0].value;
      }
    }

    function initializeSelectedInfo(initialPriorities: Priority[]) {
      admissionPointSelectedInfo.value = new Map();
      const admissionPointInfoIds = admissionPointInfoList.value?.map(
        (info) => info.id,
      );
      for (const priority of initialPriorities) {
        const priorityInfoSelection = new Map<number, PriorityInfo>();
        admissionPointSelectedInfo.value.set(
          priority.admissionPointId,
          priorityInfoSelection,
        );
        const initializedInfoIds = [];
        for (const priorityInfo of priority.infoList ?? []) {
          if (admissionPointInfoIds?.includes(priorityInfo.infoId)) {
            priorityInfoSelection.set(priorityInfo.infoId, priorityInfo);
            initializedInfoIds.push(priorityInfo.infoId);
          }
        }
        for (const info of admissionPointInfoList.value ?? []) {
          if (
            !initializedInfoIds.includes(info.id) &&
            isInfoSupported(priority.admissionPointId, info)
          ) {
            const newPriorityInfo = createNewPriorityInfo(info);
            resetInfoValue(
              newPriorityInfo,
              info,
              getDefaultValue(priority.admissionPointId, info.id),
            );
            priorityInfoSelection.set(info.id, newPriorityInfo);
            initializedInfoIds.push(info.id);
          }
        }
      }
    }

    function isInfoSupported(admissionPointId: number, info: Info) {
      return admissionPointInfoValueListMap.value
        .get(admissionPointId)
        ?.has(info.id);
    }

    function createNewPriorityInfo(info: Info): PriorityInfo {
      return {
        infoId: info.id,
        infoValue: "",
      };
    }

    function updateInfoValue<T>(
      info: Info,
      admissionPointId: number,
      newValue: T,
      otherAvailableValues?: string[] | number[] | boolean[],
    ): void {
      const selectedInfo =
        admissionPointSelectedInfo.value.get(admissionPointId);
      updateSelectedInfo(
        info,
        newValue,
        selectedInfo,
        admissionPointId,
        admissionPointInfoValueListMap.value,
        otherAvailableValues,
      );
    }

    function getCheckboxValue(info: Info, admissionPointId: number) {
      const selectedInfo =
        admissionPointSelectedInfo.value.get(admissionPointId);
      return getCheckboxInfoValue(info, selectedInfo);
    }

    function getTextValue(info: Info, admissionPointId: number) {
      const selectedInfo =
        admissionPointSelectedInfo.value.get(admissionPointId);
      return getTextInfoValue(info, selectedInfo);
    }

    function getMultipleSelectValue(info: Info, admissionPointId: number) {
      const selectedInfo =
        admissionPointSelectedInfo.value.get(admissionPointId);
      return getMultipleSelectInfoValue(
        info,
        selectedInfo,
        admissionPointId,
        admissionPointInfoValueListMap.value,
      );
    }

    return {
      admissionPointSelectedInfo,
      initializeSelectedInfo,
      updateInfoValue,
      getCheckboxValue,
      getTextValue,
      getMultipleSelectValue,
      groupVisibleInfoMap,
      exactlySelectedInGroupValidationMap,
      exactlySelectedInGroupValid,
      $reset,
    };
  })();
}
