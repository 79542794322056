import { defineStore } from "pinia";
import type { IndividualInfoSelectionType } from "@/utils/infoUtils";
import type {
  ApplicationData,
  Priority,
} from "@/types/application/applicationData";

export function useApplicationDataStore(id: string) {
  return defineStore(id + "ApplicationData", () => {
    const applicationData = ref<ApplicationData>();

    const isCurrentLevel = computed(() => {
      return (
        applicationData.value !== undefined &&
        applicationData.value.application.levelId ===
          applicationData.value.variables.currentLevelId
      );
    });

    const onlyMySchoolPriority = computed((): boolean => {
      return (
        applicationData.value !== undefined &&
        !applicationData.value.variables.isPublic &&
        ((applicationData.value.formAdmissionPointData.options
          .onlyMySchoolPriorityCurrent &&
          isCurrentLevel.value) ||
          (applicationData.value.formAdmissionPointData.options
            .onlyMySchoolPriorityOther &&
            !isCurrentLevel.value))
      );
    });

    const prioritiesDisabled = computed(() => {
      return !applicationData.value?.variables.formPartsPermissions[
        "priorities"
      ].editable;
    });

    const motherPesel = computed(() => {
      return applicationData.value?.application.student.mother.pesel;
    });

    const fatherPesel = computed(() => {
      return applicationData.value?.application.student.father.pesel;
    });

    function initialize(initialApplicationData: ApplicationData) {
      applicationData.value = initialApplicationData;
      const admissionPointStructuresStore =
        useAdmissionPointStructuresStore(id);
      const prioritySelectionStore = usePrioritySelectionStore(id);
      const criteriaStructuresStore = useCriteriaStructuresStore(id);
      const criteriaSelectionStore = useCriteriaSelectionStore(id);
      admissionPointStructuresStore.initialize(
        initialApplicationData.formAdmissionPointData.admissionPointList,
        initialApplicationData.formAdmissionPointData.admissionPointTypeList,
        initialApplicationData.formAdmissionPointData.qualificationGroupList,
        initialApplicationData.formAdmissionPointData.schoolDetailsMap,
      );
      prioritySelectionStore.initialize(
        initialApplicationData.application.priorities,
        initialApplicationData.formAdmissionPointData.options
          .enableQualificationGroupOrder,
      );
      initializeInfoStores(initialApplicationData);
      criteriaStructuresStore.criteriaData =
        initialApplicationData.formTemplateData.criteriaData;
      criteriaStructuresStore.admissionPointCriteriaMap =
        initialApplicationData.formAdmissionPointData.admissionPointCriteriaData.admissionPointCriteriaMap;
      criteriaSelectionStore.dynamicCriteriaMap =
        initialApplicationData.variables.dynamicCriteriaMap;
      criteriaSelectionStore.additionalCriteriaList =
        initialApplicationData.variables.additionalCriteriaList;
      criteriaSelectionStore.initializeSelectedCriteria(
        initialApplicationData.application.criteriaIdList,
      );
      criteriaSelectionStore.initializeSelectedCriteriaIndividual(
        initialApplicationData.application.priorities,
      );
      prioritySelectionStore.$subscribe(() => {
        if (applicationData.value) {
          applicationData.value.application.priorities =
            prioritySelectionStore.priorities;
        }
      });
      criteriaSelectionStore.$subscribe(() => {
        if (applicationData.value) {
          applicationData.value.application.criteriaIdList =
            criteriaSelectionStore.criteriaIdsApplication;
          for (const priority of applicationData.value.application.priorities) {
            priority.criteriaIdList =
              criteriaSelectionStore.criteriaIdsPriority[
                priority.admissionPointId
              ];
          }
          initialApplicationData.variables.dynamicCriteriaMap =
            criteriaSelectionStore.dynamicCriteriaMap;
          initialApplicationData.variables.additionalCriteriaList =
            criteriaSelectionStore.additionalCriteriaList;
        }
      });
    }

    function initializeInfoStores(initialApplicationData: ApplicationData) {
      const infoDataStore = useInfoDataStore(id);
      infoDataStore.initializeInfoDataStore(
        initialApplicationData.formTemplateData.infoData,
        initialApplicationData.formTemplateData.infoDataNewPanel,
      );
      const infoSelectionStore = useInfoSelectionStore(id);
      const admissionPointInfoSelectionStore =
        useAdmissionPointInfoSelectionStore(id);
      const qualificationGroupInfoSelectionStore =
        useQualificationGroupInfoSelectionStore(id);

      infoSelectionStore.initializeSelectedInfo(
        initialApplicationData.application.infoList,
      );
      admissionPointInfoSelectionStore.initializeSelectedInfo(
        initialApplicationData.application.priorities,
      );
      qualificationGroupInfoSelectionStore.initializeSelectedInfo(
        initialApplicationData.application.priorities,
      );
      infoSelectionStore.$subscribe((_mutation, state) => {
        if (applicationData.value) {
          applicationData.value.application.infoList = Array.from(
            state.selectedInfo.entries(),
          ).map((entry) => entry[1]);
        }
      });
      admissionPointInfoSelectionStore.$subscribe((_mutation, state) => {
        if (applicationData.value) {
          updatePriorityInfo(
            applicationData.value.application.priorities,
            state.admissionPointSelectedInfo,
            qualificationGroupInfoSelectionStore.qualificationGroupSelectedInfo,
          );
        }
      });
      qualificationGroupInfoSelectionStore.$subscribe((_mutation, state) => {
        if (applicationData.value) {
          updatePriorityInfo(
            applicationData.value.application.priorities,
            admissionPointInfoSelectionStore.admissionPointSelectedInfo,
            state.qualificationGroupSelectedInfo,
          );
        }
      });
    }

    function updatePriorityInfo(
      priorities: Priority[],
      admissionPointSelectedInfoMap: Map<number, IndividualInfoSelectionType>,
      qualificationGroupSelectedInfoMap: Map<
        number,
        IndividualInfoSelectionType
      >,
    ) {
      for (const priority of priorities) {
        const updatedInfoIds: number[] = [];
        const admissionPointSelectedInfo = admissionPointSelectedInfoMap.get(
          priority.admissionPointId,
        );
        if (admissionPointSelectedInfo) {
          const admissionPointInfoIds = updateOrAddInfoValue(
            admissionPointSelectedInfo,
            priority,
          );
          updatedInfoIds.push(...admissionPointInfoIds);
        }
        const qualificationGroupSelectedInfo =
          qualificationGroupSelectedInfoMap.get(priority.qualificationGroupId);
        if (qualificationGroupSelectedInfo) {
          const qualificationGroupInfoIds = updateOrAddInfoValue(
            qualificationGroupSelectedInfo,
            priority,
          );
          updatedInfoIds.push(...qualificationGroupInfoIds);
        }
        priority.infoList = priority.infoList?.filter((info) =>
          updatedInfoIds.includes(info.infoId),
        );
      }
    }

    function updateOrAddInfoValue(
      qualificationGroupSelectedInfo: IndividualInfoSelectionType,
      priority: Priority,
    ): number[] {
      const updatedInfoIds: number[] = [];
      if (priority.infoList === undefined) {
        priority.infoList = [];
      }
      for (const selectedInfo of qualificationGroupSelectedInfo.values() ??
        []) {
        updatedInfoIds.push(selectedInfo.infoId);
        const priorityInfo = priority.infoList.find(
          (info) => info.infoId === selectedInfo.infoId,
        );
        if (priorityInfo) {
          priorityInfo.infoValue = selectedInfo.infoValue;
        } else {
          const selectedInfoCopy = { ...selectedInfo };
          delete selectedInfoCopy.id;
          priority.infoList.push(selectedInfoCopy);
        }
      }
      return updatedInfoIds;
    }

    function $reset() {
      useRegionStore(id).$reset();
      useAdmissionPointStructuresStore(id).$reset();
      usePrioritySelectionStore(id).$reset();
      useCriteriaStructuresStore(id).$reset();
      useCriteriaSelectionStore(id).$reset();
      useInfoDataStore(id).$reset();
      useInfoSelectionStore(id).$reset();
      useAdmissionPointInfoSelectionStore(id).$reset();
      useQualificationGroupInfoSelectionStore(id).$reset();
      applicationData.value = undefined;
    }

    return {
      initialize,
      applicationData,
      motherPesel,
      fatherPesel,
      isCurrentLevel,
      onlyMySchoolPriority,
      prioritiesDisabled,
      $reset,
    };
  })();
}
