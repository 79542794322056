import type {
  AdmissionPointType,
  ApplicationType,
  Priority,
} from "@/types/application/applicationData";
import { defineStore, storeToRefs } from "pinia";

export function usePrioritySelectionStore(id: string) {
  return defineStore(id + "PrioritySelection", () => {
    const admissionPointSelection = ref<number[] | undefined>();
    const existingApplicationPriorities = ref<Map<number, Priority>>();
    const priorities = ref<Priority[]>([]);
    const enableQualificationGroupOrder = ref<boolean>(false);

    function $reset() {
      admissionPointSelection.value = undefined;
      existingApplicationPriorities.value = undefined;
      priorities.value = [];
      enableQualificationGroupOrder.value = false;
    }

    const { admissionPointMap, admissionPointTypeMap, qualificationGroupMap } =
      storeToRefs(useAdmissionPointStructuresStore(id));

    function initialize(
      initialPriorities: Priority[],
      initialEnableQualificationGroupOrder: boolean,
    ) {
      priorities.value = [];
      enableQualificationGroupOrder.value =
        initialEnableQualificationGroupOrder;
      existingApplicationPriorities.value = new Map(
        initialPriorities.map((priority) => [
          priority.admissionPointId,
          priority,
        ]),
      );
      admissionPointSelection.value = initialPriorities.map(
        (priority) => priority.admissionPointId,
      );
    }

    watch(admissionPointSelection, (newValue, oldValue) => {
      let removedAdmissionPointIds: number[] = [];
      if (oldValue !== undefined) {
        removedAdmissionPointIds = oldValue.filter(
          (admissionPointId) =>
            newValue === undefined || !newValue.includes(admissionPointId),
        );
      }
      let addedAdmissionPointIds: number[] = [];
      if (newValue !== undefined) {
        addedAdmissionPointIds = newValue.filter(
          (admissionPointId) =>
            oldValue === undefined ||
            priorities.value.length === 0 ||
            !oldValue.includes(admissionPointId),
        );
      }
      priorities.value = priorities.value.filter(
        (priority) =>
          !removedAdmissionPointIds.includes(priority.admissionPointId),
      );
      for (const addedAdmissionPointId of addedAdmissionPointIds) {
        const admissionPoint = admissionPointMap.value.get(
          addedAdmissionPointId,
        );
        if (admissionPoint) {
          const qualificationGroupId = admissionPoint.qualificationGroupId;
          const priorityCount = newValue?.filter((admissionPointId) => {
            const qualificationGroupId =
              admissionPointMap.value.get(
                admissionPointId,
              )?.qualificationGroupId;
            return (
              qualificationGroupId !== undefined &&
              qualificationGroupId === admissionPoint.qualificationGroupId
            );
          }).length;
          const newPriority: Priority = {
            admissionPointId: addedAdmissionPointId,
            criteriaIdList: [],
            priority: priorityCount ?? 1,
            qualificationGroupId: qualificationGroupId,
            qualificationGroupOrder:
              qualificationGroupMap.value.get(qualificationGroupId)?.ordering ??
              1,
            priorityConfirmation: false,
            autoAdded: false,
            admissionPoint: {
              id: addedAdmissionPointId,
              schoolId: admissionPoint.schoolId,
              qualificationGroupId: admissionPoint.qualificationGroupId,
              fullName: admissionPoint.admissionFullname,
            },
          };
          const existingPriority = existingApplicationPriorities.value?.get(
            addedAdmissionPointId,
          );
          if (existingPriority) {
            newPriority.id = existingPriority.id;
            newPriority.autoAdded = existingPriority.autoAdded;
            newPriority.priorityConfirmation =
              existingPriority.priorityConfirmation;
            newPriority.criteriaIdList = existingPriority.criteriaIdList;
            newPriority.infoList = existingPriority.infoList;
            newPriority.continuing = existingPriority.continuing;
          }

          priorities.value.push(newPriority);
        }
      }
      priorities.value = orderedPriorities(
        priorities.value,
        enableQualificationGroupOrder.value,
      );
    });

    const selectedQualificationGroupIds = computed(() => {
      const resultList: number[] = [];
      if (admissionPointSelection.value === undefined) {
        return resultList;
      }
      for (const admissionPointId of admissionPointSelection.value) {
        const admissionPoint = admissionPointMap.value.get(admissionPointId);
        if (
          admissionPoint !== undefined &&
          !resultList.includes(admissionPoint.qualificationGroupId)
        ) {
          resultList.push(admissionPoint.qualificationGroupId);
        }
      }
      return resultList;
    });

    const selectedSchoolIds = computed(() => {
      const resultList: number[] = [];
      if (admissionPointSelection.value === undefined) {
        return resultList;
      }
      for (const admissionPointId of admissionPointSelection.value) {
        const admissionPoint = admissionPointMap.value.get(admissionPointId);
        if (
          admissionPoint !== undefined &&
          !resultList.includes(admissionPoint.schoolId)
        ) {
          resultList.push(admissionPoint.schoolId);
        }
      }
      return resultList;
    });

    const admissionPointPriorityMap = computed(() => {
      return new Map(
        priorities.value.map((priority) => [
          priority.admissionPointId,
          priority,
        ]),
      );
    });

    const schoolIdsWithPriorityConfirmation = computed(() => {
      const resultList: number[] = [];
      if (priorities.value === undefined) {
        return resultList;
      }
      for (const priority of priorities.value) {
        if (
          priority.priorityConfirmation &&
          !resultList.includes(priority.admissionPoint.schoolId)
        ) {
          resultList.push(priority.admissionPoint.schoolId);
        }
      }
      return resultList;
    });

    const qualificaitonGroupPriorityMap = computed(() => {
      const resultMap = new Map<number, Priority[]>();
      for (const priority of priorities.value) {
        if (!resultMap.has(priority.qualificationGroupId)) {
          resultMap.set(priority.qualificationGroupId, []);
        }
        resultMap.get(priority.qualificationGroupId)?.push(priority);
      }
      return resultMap;
    });

    const qualificaitonGroupPriorityNotAutoAddedMap = computed(() => {
      const resultMap = new Map<number, Priority[]>();
      for (const priority of priorities.value) {
        if (!resultMap.has(priority.qualificationGroupId)) {
          resultMap.set(priority.qualificationGroupId, []);
        }
        if (!priority.autoAdded) {
          resultMap.get(priority.qualificationGroupId)?.push(priority);
        }
      }
      return resultMap;
    });

    const priorityCountMap = computed(() => {
      const resultMap = new Map<number, number>();
      admissionPointSelection.value?.forEach((admissionPointId) => {
        const admissionPoint = admissionPointMap.value.get(admissionPointId);
        if (admissionPoint !== undefined) {
          const currentCount = resultMap.get(
            admissionPoint.qualificationGroupId,
          );
          const selectedPriority =
            admissionPointPriorityMap.value.get(admissionPointId);
          if (currentCount && selectedPriority && !selectedPriority.autoAdded) {
            resultMap.set(
              admissionPoint.qualificationGroupId,
              currentCount + 1,
            );
          } else if (!currentCount) {
            resultMap.set(admissionPoint.qualificationGroupId, 1);
          }
        }
      });

      return resultMap;
    });

    const applicationTypeChangePriorityMap = computed(() => {
      let previousApplicationType: ApplicationType | null | undefined = null;
      const resultMap = new Map<number, Priority>();
      for (const [
        qualificationGroupId,
        priorityList,
      ] of qualificaitonGroupPriorityMap.value) {
        for (const priority of priorityList) {
          const admissionPointTypeId = admissionPointMap.value.get(
            priority.admissionPointId,
          )?.admissionPointTypeId;
          const admissionPointType =
            admissionPointTypeId !== undefined
              ? admissionPointTypeMap.value.get(admissionPointTypeId)
              : undefined;
          const applicationType = admissionPointType?.applicationType;
          if (
            applicationType &&
            previousApplicationType &&
            applicationType._name !== previousApplicationType._name
          ) {
            resultMap.set(qualificationGroupId, priority);
            break;
          }
          previousApplicationType = applicationType;
        }
      }
      return resultMap;
    });

    const admissionPointTypeSelection = computed(() => {
      const admissionPointTypes: AdmissionPointType[] = [];
      if (admissionPointSelection.value) {
        admissionPointSelection.value.forEach((admissionPointId) => {
          const admissionPoint = admissionPointMap.value.get(admissionPointId);
          if (admissionPoint) {
            const admissionPointType = admissionPointTypeMap.value.get(
              admissionPoint?.admissionPointTypeId,
            );

            if (admissionPointType !== undefined) {
              admissionPointTypes.push(admissionPointType);
            }
          }
        });
      }
      return admissionPointTypes;
    });

    const qualificaitonGroupSchoolIdsMap = computed(() => {
      const resultMap = new Map<number, Set<number>>();
      for (const priority of priorities.value) {
        if (!resultMap.has(priority.qualificationGroupId)) {
          resultMap.set(priority.qualificationGroupId, new Set());
        }
        resultMap
          .get(priority.qualificationGroupId)
          ?.add(priority.admissionPoint.schoolId);
      }
      return resultMap;
    });

    function removePriority(removedAdmissionPointId: number) {
      if (admissionPointSelection.value) {
        admissionPointSelection.value = admissionPointSelection.value.filter(
          (admissionPointId) => admissionPointId !== removedAdmissionPointId,
        );
        existingApplicationPriorities.value?.delete(removedAdmissionPointId);
      }
    }

    function movePriorityUp(index: number) {
      if (index > 0 && priorities.value !== undefined) {
        const previousPriorityQualificationGroupId =
          admissionPointMap.value.get(
            priorities.value[index - 1].admissionPointId,
          )?.qualificationGroupId;
        const priorityQualificationGroupId = admissionPointMap.value.get(
          priorities.value[index].admissionPointId,
        )?.qualificationGroupId;
        if (
          previousPriorityQualificationGroupId === priorityQualificationGroupId
        ) {
          [priorities.value[index - 1], priorities.value[index]] = [
            priorities.value[index],
            priorities.value[index - 1],
          ];
          const existingPriority = existingApplicationPriorities.value?.get(
            priorities.value[index].admissionPointId,
          );
          const existingPreviousPriority =
            existingApplicationPriorities.value?.get(
              priorities.value[index - 1].admissionPointId,
            );
          if (existingPriority) {
            existingPriority.autoAdded = false;
          }
          if (existingPreviousPriority) {
            existingPreviousPriority.autoAdded = false;
          }
          priorities.value = orderedPriorities(
            priorities.value,
            enableQualificationGroupOrder.value,
          );
        }
      }
    }

    function movePriorityDown(index: number) {
      if (
        priorities.value !== undefined &&
        index < priorities.value.length - 1
      ) {
        const nextPriorityQualificationGroupId = admissionPointMap.value.get(
          priorities.value[index + 1].admissionPointId,
        )?.qualificationGroupId;
        const priorityQualificationGroupId = admissionPointMap.value.get(
          priorities.value[index].admissionPointId,
        )?.qualificationGroupId;
        if (nextPriorityQualificationGroupId === priorityQualificationGroupId) {
          [priorities.value[index], priorities.value[index + 1]] = [
            priorities.value[index + 1],
            priorities.value[index],
          ];
          const existingPriority = existingApplicationPriorities.value?.get(
            priorities.value[index].admissionPointId,
          );
          const existingNextPriority = existingApplicationPriorities.value?.get(
            priorities.value[index + 1].admissionPointId,
          );
          if (existingPriority) {
            existingPriority.autoAdded = false;
          }
          if (existingNextPriority) {
            existingNextPriority.autoAdded = false;
          }
          priorities.value = orderedPriorities(
            priorities.value,
            enableQualificationGroupOrder.value,
          );
        }
      }
    }

    function orderedPriorities(
      priorities: Priority[],
      reassignQualificationGroupOrder: boolean,
    ): Priority[] {
      const qualificationGroupOrderings = getQualificationGroupOrderings(
        priorities,
        reassignQualificationGroupOrder,
      );

      const sortedPrioritiesByQualificationGroups =
        getSortedPrioritiesByQualificationGroups(
          priorities,
          qualificationGroupOrderings,
        );

      const resultPriorities: Priority[] = [];
      for (const entry of sortedPrioritiesByQualificationGroups) {
        for (let i = 0; i < entry[1].length; i++) {
          entry[1][i].priority = i + 1;
          const newQualificationGroupOrder = qualificationGroupOrderings.get(
            entry[1][i].qualificationGroupId,
          );
          if (reassignQualificationGroupOrder && newQualificationGroupOrder) {
            entry[1][i].qualificationGroupOrder = newQualificationGroupOrder;
          }
        }
        resultPriorities.push(...entry[1]);
      }

      return resultPriorities;
    }

    function getQualificationGroupOrderings(
      priorities: Priority[],
      reassign: boolean,
    ): Map<number, number> {
      let qualificationGroupOrder = 1;
      const qualificationGroupOrderings = new Map<number, number>();
      priorities.forEach((priority) => {
        if (!qualificationGroupOrderings.has(priority.qualificationGroupId)) {
          if (!reassign) {
            qualificationGroupOrderings.set(
              priority.qualificationGroupId,
              priority.qualificationGroupOrder,
            );
          } else {
            qualificationGroupOrderings.set(
              priority.qualificationGroupId,
              qualificationGroupOrder,
            );
            qualificationGroupOrder++;
          }
        }
      });
      return qualificationGroupOrderings;
    }

    function getSortedPrioritiesByQualificationGroups(
      priorities: Priority[],
      qualificationGroupOrderings: Map<number, number>,
    ): Map<number, Priority[]> {
      const prioritiesByQualificationGroups = priorities.reduce(
        (accumulator, priority) => {
          const key = priority.qualificationGroupId;
          const group = accumulator.get(key);
          if (group) {
            group.push(priority);
          } else {
            accumulator.set(key, [priority]);
          }

          return accumulator;
        },
        new Map<number, Priority[]>(),
      );

      return new Map(
        [...prioritiesByQualificationGroups].sort((x1, x2) => {
          const x1Sort = qualificationGroupOrderings.get(x1[0]);
          const x2Sort = qualificationGroupOrderings.get(x2[0]);
          if (x1Sort === undefined && x2Sort === undefined) {
            return 0;
          } else if (x1Sort === undefined) {
            return -1;
          } else if (x2Sort === undefined) {
            return 1;
          } else {
            return x1Sort - x2Sort;
          }
        }),
      );
    }

    return {
      admissionPointSelection,
      selectedQualificationGroupIds,
      selectedSchoolIds,
      priorities,
      admissionPointPriorityMap,
      qualificaitonGroupPriorityMap,
      qualificaitonGroupPriorityNotAutoAddedMap,
      priorityCountMap,
      applicationTypeChangePriorityMap,
      admissionPointTypeSelection,
      qualificaitonGroupSchoolIdsMap,
      schoolIdsWithPriorityConfirmation,
      initialize,
      removePriority,
      movePriorityUp,
      movePriorityDown,
      $reset,
    };
  })();
}
