import type {
  Info,
  InfoSelection,
  PriorityInfo,
} from "@/types/application/applicationData";

const ADVANCED_SELECT_SPLIT_CHAR = "|";

export type ExactlySelectedInGroupType = Map<number | undefined, boolean>;
export type InfoValueListType = Map<
  number,
  Record<"id" | "value" | "type", string>[]
>;

export type IndividualInfoSelectionType = Map<number, PriorityInfo>;
export type GroupVisibleInfoType = Map<number | undefined, Info[]>;

export function isInfoVisible(
  info: Info,
  selectedInfo: Map<number, InfoSelection> | undefined,
  infoMap: Map<number, Info>,
): boolean {
  if (info.relatedInfoId === undefined) {
    return true;
  }
  const relatedInfo = infoMap.get(info.relatedInfoId);
  const relatedInfoValue = selectedInfo?.get(info.relatedInfoId)?.infoValue;
  if (showAsCheckbox(relatedInfo) && relatedInfoValue === "true") {
    return true;
  }

  return false;
}

export function clearInfoIds(
  infoIds: Set<number> | number[] | undefined,
  selectedInfo: Map<number, InfoSelection> | undefined,
): void {
  if (infoIds === undefined) {
    return;
  }
  for (const infoId of infoIds) {
    const infoSelectionToReset = selectedInfo?.get(infoId);
    if (infoSelectionToReset) {
      infoSelectionToReset.infoValue = "";
    }
  }
}

export function resetInfoValue(
  applicationInfo: InfoSelection,
  info: Info,
  defaultValue?: string,
) {
  if (
    !applicationInfo.infoValue &&
    info.defaultValue &&
    (showAsTextfield(info) || showAsTextarea(info))
  ) {
    applicationInfo.infoValue = info.defaultValue;
  } else if (!applicationInfo.infoValue && showAsCheckbox(info)) {
    applicationInfo.infoValue = "false";
  } else if (defaultValue !== undefined && defaultValue !== "") {
    applicationInfo.infoValue = defaultValue;
  } else if (!applicationInfo.infoValue) {
    applicationInfo.infoValue = "";
  }
}

export function getGroupVisibleInfoMap(
  infoList: Info[] | undefined,
  visibleInfoIds: Set<number> | undefined,
): Map<number | undefined, Info[]> {
  const resultMap = new Map<number | undefined, Info[]>();
  for (const info of infoList ?? []) {
    if (visibleInfoIds == undefined || !visibleInfoIds.has(info.id)) {
      continue;
    }
    const existingGroupInfoList = resultMap.get(info.groupId);
    if (existingGroupInfoList) {
      existingGroupInfoList.push(info);
    } else {
      resultMap.set(info.groupId, [info]);
    }
  }
  return resultMap;
}

export function getExactlySelectedInGroupValidationMap(
  groupVisibleInfoMap: Map<number | undefined, Info[]> | undefined,
  selectedInfo: Map<number, InfoSelection> | undefined,
): ExactlySelectedInGroupType {
  const validationMap: ExactlySelectedInGroupType = new Map();
  for (const [groupId, infoList] of groupVisibleInfoMap ?? []) {
    let selectedInGroup = 0;
    let requiredSelectedInGroup = null;
    for (let i = 0; i < infoList.length; i++) {
      if (
        i === 0 &&
        infoList[i].exactSelectedInGroup !== null &&
        infoList[i].exactSelectedInGroup !== undefined
      ) {
        requiredSelectedInGroup = infoList[i].exactSelectedInGroup;
      }
      if (requiredSelectedInGroup !== infoList[i].exactSelectedInGroup) {
        requiredSelectedInGroup = null;
        break;
      }
      const selectedInfoEntry = selectedInfo?.get(infoList[i].id);
      if (
        selectedInfoEntry !== undefined &&
        selectedInfoEntry.infoValue !== "" &&
        selectedInfoEntry.infoValue !== "false"
      ) {
        selectedInGroup++;
      }
    }

    if (
      requiredSelectedInGroup !== null &&
      selectedInGroup !== requiredSelectedInGroup
    ) {
      validationMap.set(groupId, false);
    } else {
      validationMap.set(groupId, true);
    }
  }

  return validationMap;
}

export function isExactlySelectedInGroupValid(
  exactlySelectedInGroupValidationMap: ExactlySelectedInGroupType,
): boolean {
  return Array.from(exactlySelectedInGroupValidationMap.values()).every(
    (valid) => valid,
  );
}

export function updateCheckbox(
  info: Info,
  newValue: boolean | undefined,
  selectedInfo: Map<number, InfoSelection> | undefined,
): void {
  const selectedInfoEntry = selectedInfo?.get(info.id);
  if (selectedInfoEntry && showAsCheckbox(info)) {
    selectedInfoEntry.infoValue =
      newValue !== undefined ? newValue.toString() : "false";
  }
}

function updateText(
  info: Info,
  newValue: string | number | undefined,
  selectedInfo: Map<number, InfoSelection> | undefined,
  admissionPointId: number | undefined,
  admissionPointInfoValueListMap: Map<number, InfoValueListType> | undefined,
): void {
  const selectedInfoEntry = selectedInfo?.get(info.id);
  if (
    selectedInfoEntry &&
    (showAsTextfield(info) ||
      showAsTextarea(info) ||
      showAsHourRange(info, admissionPointId, admissionPointInfoValueListMap))
  ) {
    selectedInfoEntry.infoValue =
      newValue !== undefined ? newValue.toString() : "";
  }
}

function updateMultipleSelect(
  info: Info,
  newValue:
    | string
    | number
    | boolean
    | string[]
    | number[]
    | boolean[]
    | undefined,
  selectedInfo: Map<number, InfoSelection> | undefined,
  admissionPointId: number | undefined,
  admissionPointInfoValueListMap: Map<number, InfoValueListType> | undefined,
  otherAvailableValues?: string[] | number[] | boolean[],
): void {
  const selectedInfoEntry = selectedInfo?.get(info.id);
  if (
    selectedInfoEntry &&
    showAsMultipleSelect(info, admissionPointId, admissionPointInfoValueListMap)
  ) {
    if (newValue === undefined) {
      selectedInfoEntry.infoValue = "";
      return;
    }
    let newValueArray = Array.isArray(newValue)
      ? newValue
      : ([newValue] as string[] | number[] | boolean[]);
    const infoAvailableValues = getInfoAvailableValues(
      info,
      otherAvailableValues,
    );
    newValueArray = filterValuesNotInList(newValueArray, infoAvailableValues);
    selectedInfoEntry.infoValue = newValueArray.join(";");
  }
}

export function getInfoAvailableValues(
  info: Info | undefined,
  otherAvailableValues?: string[] | number[] | boolean[],
): string[] | number[] | boolean[] {
  let availableValues: string[] | number[] | boolean[] | undefined =
    info?.parametersList;
  if (
    info?.parametersList &&
    info.parametersList[0].includes(ADVANCED_SELECT_SPLIT_CHAR)
  ) {
    availableValues = info.parametersList
      .map((parameter) => parameter.split(ADVANCED_SELECT_SPLIT_CHAR)[0])
      .flat();
  } else if (info?.parametersList && info.parametersList.length > 0) {
    availableValues = info.parametersList;
  } else if (otherAvailableValues) {
    availableValues = otherAvailableValues;
  }

  return availableValues ?? [];
}

export function filterValuesNotInList(
  values: string[] | number[] | boolean[],
  availableValues: string[] | number[] | boolean[],
): string[] | number[] | boolean[] {
  const availableValuesStringArray = availableValues.map((value) =>
    value.toString(),
  );
  if (isNumberArray(values)) {
    return values.filter((value) => {
      return availableValuesStringArray.includes(value.toString());
    });
  } else if (isStringArray(values)) {
    return values.filter((value) => {
      return availableValuesStringArray.includes(value);
    });
  }

  return values.filter((value) => {
    return availableValuesStringArray.includes(value.toString());
  });
}

export function updateSelectedInfo<T>(
  info: Info,
  newValue: T,
  selectedInfo: Map<number, InfoSelection> | undefined,
  admissionPointId: number | undefined,
  admissionPointInfoValueListMap: Map<number, InfoValueListType> | undefined,
  otherAvailableValues?: string[] | number[] | boolean[],
) {
  if (showAsCheckbox(info) && isCheckboxValueType(newValue)) {
    updateCheckbox(info, newValue, selectedInfo);
  } else if (
    (showAsTextarea(info) ||
      showAsTextfield(info) ||
      showAsHourRange(
        info,
        admissionPointId,
        admissionPointInfoValueListMap,
      )) &&
    isTextValueType(newValue)
  ) {
    updateText(
      info,
      newValue,
      selectedInfo,
      admissionPointId,
      admissionPointInfoValueListMap,
    );
  } else if (
    showAsMultipleSelect(
      info,
      admissionPointId,
      admissionPointInfoValueListMap,
    ) &&
    isMultipleSelectValueType(newValue)
  ) {
    updateMultipleSelect(
      info,
      newValue,
      selectedInfo,
      admissionPointId,
      admissionPointInfoValueListMap,
      otherAvailableValues,
    );
  }
}

export function showAsMultipleSelect(
  info: Info | undefined,
  admissionPointId: number | undefined,
  admissionPointInfoValueListMap: Map<number, InfoValueListType> | undefined,
): boolean {
  if (
    admissionPointId !== undefined &&
    info !== undefined &&
    info?.associatedWithOfferField === true
  ) {
    const valueList = admissionPointInfoValueListMap
      ?.get(admissionPointId)
      ?.get(info.id);
    if (valueList !== undefined && valueList.length > 0) {
      return valueList[0].type !== "HOUR_RANGE";
    }
  }
  return (
    info?.infoType._name === "MULTIPLESELECT" ||
    info?.associatedWithOfferField === true
  );
}

export function showAsHourRange(
  info: Info | undefined,
  admissionPointId?: number,
  admissionPointInfoValueListMap?: Map<number, InfoValueListType>,
): boolean {
  if (
    admissionPointId !== undefined &&
    info !== undefined &&
    info?.associatedWithOfferField === true
  ) {
    const valueList = admissionPointInfoValueListMap
      ?.get(admissionPointId)
      ?.get(info.id);
    if (valueList !== undefined && valueList.length > 0) {
      return valueList[0].type === "HOUR_RANGE";
    }
  }
  return false;
}

export function showAsCheckbox(info: Info | undefined): boolean {
  return info?.infoType._name === "CHECKBOX" && !info?.associatedWithOfferField;
}

export function showAsTextfield(info: Info | undefined): boolean {
  return (
    info?.infoType._name === "TEXTFIELD" && !info?.associatedWithOfferField
  );
}

export function showAsTextarea(info: Info | undefined): boolean {
  return info?.infoType._name === "TEXTAREA" && !info?.associatedWithOfferField;
}

export function getCheckboxInfoValue(
  info: Info,
  selectedInfo: Map<number, InfoSelection> | undefined,
): boolean | undefined {
  const selectedInfoEntry = selectedInfo?.get(info.id);
  return selectedInfoEntry && showAsCheckbox(info)
    ? Boolean(selectedInfoEntry.infoValue === "true")
    : undefined;
}

export function getTextInfoValue(
  info: Info,
  selectedInfo: Map<number, InfoSelection> | undefined,
): string | undefined {
  const infoEntry = selectedInfo?.get(info.id);
  return infoEntry && (showAsTextfield(info) || showAsTextarea(info))
    ? infoEntry.infoValue
    : undefined;
}

export function getMultipleSelectInfoValue(
  info: Info,
  selectedInfo: Map<number, InfoSelection> | undefined,
  admissionPointId: number | undefined,
  admissionPointInfoValueListMap: Map<number, InfoValueListType> | undefined,
): string | string[] | undefined {
  const infoEntry = selectedInfo?.get(info.id);
  if (
    infoEntry &&
    showAsMultipleSelect(info, admissionPointId, admissionPointInfoValueListMap)
  ) {
    if (
      (info.maxSelected && info.maxSelected === 1) ||
      info.associatedWithOfferField
    ) {
      return infoEntry.infoValue;
    } else {
      return infoEntry.infoValue ? infoEntry.infoValue.split(";") : undefined;
    }
  }
}

function isCheckboxValueType(value: unknown): value is boolean | undefined {
  return typeof value === "boolean" || value === undefined;
}

function isTextValueType(value: unknown): value is string | number | undefined {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    value === undefined
  );
}

function isMultipleSelectValueType(
  value: unknown,
): value is
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | undefined {
  return (
    typeof value === "string" ||
    typeof value === "number" ||
    typeof value === "boolean" ||
    isStringArray(value) ||
    isBooleanArray(value) ||
    isNumberArray(value) ||
    value === undefined
  );
}

export function isAdvancedSelect(
  info: Info,
  admissionPointId: number | undefined,
  admissionPointInfoValueListMap: Map<number, InfoValueListType> | undefined,
) {
  return (
    info &&
    showAsMultipleSelect(
      info,
      admissionPointId,
      admissionPointInfoValueListMap,
    ) &&
    info.parametersList &&
    info.parametersList[0].includes(ADVANCED_SELECT_SPLIT_CHAR) &&
    !info.associatedWithOfferField
  );
}
