import { defineStore, storeToRefs } from "pinia";
import type { Priority } from "~/types/application/applicationData";

export function useRegionStore(id: string) {
  return defineStore(id + "RegionStore", () => {
    const regionQuery = ref<{
      cityId: string | number;
      locationId: string | number | undefined;
      street: string | number;
      house: string | number;
    }>();

    function $reset() {
      regionQuery.value = undefined;
    }

    const { admissionPointMap } = storeToRefs(
      useAdmissionPointStructuresStore(id),
    );
    const { applicationData } = storeToRefs(useApplicationDataStore(id));
    const { priorities } = storeToRefs(usePrioritySelectionStore(id));

    const { data: regionAndDistances } = useApi(
      "/api/static/regionAndDistances",
      {
        method: "get",
        query: regionQuery,
        immediate: false,
      },
    );

    const elementaryRegionSchoolId = computed(() => {
      if (
        applicationData.value?.formTemplateData.options
          .regionCriteriaFromService
      ) {
        return regionAndDistances.value?.elementaryRegionSchoolId;
      } else {
        return applicationData.value?.variables.regionSchoolId
          ? applicationData.value?.variables.regionSchoolId
          : 0;
      }
    });

    watchThrottled(
      () => [
        applicationData.value?.application.student.contactAddress.communityId,
        applicationData.value?.application.student.contactAddress.cityId,
        applicationData.value?.application.student.contactAddress.locationId,
        applicationData.value?.application.student.contactAddress.street,
        applicationData.value?.application.student.contactAddress.house,
      ],
      async ([communityId, cityId, locationId, street, house]) => {
        if (
          applicationData.value?.formTemplateData.criteriaData.options
            .regionCriteriaPresent &&
          applicationData.value?.formTemplateData.options.communityId ===
            communityId &&
          cityId !== undefined &&
          street &&
          house
        ) {
          regionQuery.value = {
            cityId,
            locationId,
            street,
            house,
          };
        } else {
          regionAndDistances.value = null;
        }
      },
      { throttle: 1000 },
    );

    const firstRegionPriorities = computed(() => {
      const resultMap = new Map<number, Priority>();
      const regionCriteriaDefinitionId =
        applicationData.value?.formTemplateData.criteriaData
          .regionCriteriaDefinitionId;
      if (regionCriteriaDefinitionId !== undefined) {
        for (let i = 0; i < priorities.value.length; i++) {
          const admissionPoint = admissionPointMap.value.get(
            priorities.value[i].admissionPointId,
          );
          if (
            admissionPoint &&
            regionAdmissionIds.value?.includes(
              priorities.value[i].admissionPointId,
            ) &&
            !resultMap.has(admissionPoint.qualificationGroupId)
          ) {
            return resultMap.set(
              admissionPoint.qualificationGroupId,
              priorities.value[i],
            );
          }
        }
      }
      return resultMap;
    });

    const regionAdmissionIds = computed(() => {
      const regionCriteriaDefinitionId =
        applicationData.value?.formTemplateData.criteriaData
          .regionCriteriaDefinitionId;
      if (regionCriteriaDefinitionId !== undefined && applicationData.value) {
        return applicationData.value.formAdmissionPointData.admissionPointList
          .filter((admissionPoint) => {
            const admissionPointCriteriaDefinitionIds =
              applicationData.value?.formAdmissionPointData
                .admissionPointCriteriaData.admissionPointCriteriaMap[
                admissionPoint.admissionPointId
              ];
            const isInRegionSchool =
              admissionPoint.schoolId === elementaryRegionSchoolId.value;
            return (
              admissionPointCriteriaDefinitionIds &&
              isInRegionSchool &&
              admissionPointCriteriaDefinitionIds.includes(
                regionCriteriaDefinitionId,
              )
            );
          })
          .map((admissionPoint) => admissionPoint.admissionPointId);
      }
      return [];
    });

    const regionPriorities = computed(() => {
      if (
        applicationData.value?.formTemplateData.criteriaData
          .regionCriteriaDefinitionId === undefined
      ) {
        return [];
      }
      return applicationData.value?.application.priorities.filter((priority) =>
        regionAdmissionIds.value.includes(priority.admissionPointId),
      );
    });

    const regionPrioritiesAdmissionPointIds = computed(() => {
      return regionPriorities.value.map((p) => p.admissionPointId);
    });

    const isRegionOnly = computed(() => {
      return (
        regionPriorities.value.length ===
          applicationData.value?.application.priorities.length &&
        applicationData.value?.application.priorities.length > 0
      );
    });

    return {
      regionAdmissionIds,
      firstRegionPriorities,
      isRegionOnly,
      regionPriorities,
      regionPrioritiesAdmissionPointIds,
      elementaryRegionSchoolId,
      $reset,
    };
  })();
}
